<template>
  <v-row class="pt-16" justify="center">
    <v-col
        cols="12"
        sm="10"
        md="8"
        lg="6"
    >
      <v-card ref="form">
        <v-card-title style="justify-content: center">Update Group</v-card-title>
        <v-card-text class="px-8">
          <v-text-field
              ref="customUrl"
              v-model="customGroup.customUrl"
              :rules="customUrlRules"
              label="Group Name"
              readonly
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-btn style="float: right" @click="addEvent">Add Event</v-btn>
          <v-expansion-panels>
            <v-expansion-panel v-for="(event, index) in customGroup.customEvents" :key="event.id">
              <v-expansion-panel-header>{{ event.event }} (Drop down to edit)</v-expansion-panel-header>
              <v-expansion-panel-content>
                    <v-card>
                      <v-card-text class="px-8">
                        <v-text-field
                            label="Event Name"
                            v-model="event.event"
                        ></v-text-field>
                        <v-select
                          label="Duration"
                          v-model="event.duration"
                          :items="durationItems"
                          item-text="key"
                          item-value="value"
                        ></v-select>
                        <v-select
                            label="Times"
                            v-model="event.times"
                            :items="eventTimeItems"
                            multiple
                            hint="Clear then select times in order otherwise, times will not display correctly."
                            persistent-hint
                            clearable
                        ></v-select>
                        <v-select
                            label="Days of the Week"
                            v-model="event.daysOfTheWeek"
                            :items="dayOfTheWeekItems"
                            item-text="key"
                            item-value="value"
                            multiple
                            hint="Clear then select days in order otherwise, days will not display correctly."
                            persistent-hint
                            clearable
                        ></v-select>
                      </v-card-text>
                      <v-divider class="mt-12"></v-divider>
                      <v-card-actions class="px-8">
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            text
                            @click="deleteEvent(index)"
                        >
                          Delete
                        </v-btn>
                      </v-card-actions>
                    </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </v-card-text>
        <div style="color: red" v-if="formHasErrors">Make sure all fields are filled out in each section.</div>
        <v-divider class="mt-12"></v-divider>
        <v-card-actions class="px-8">
          <v-btn text @click="returnHome()">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="updateGroup"
          >
            Update Group
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import db from "@/firebase";
// import { getDoc, doc } from "firebase/firestore";
import { query, where, getDocs, collection, doc, updateDoc } from "firebase/firestore";

export default {
  name: "GroupEdit",

  data: () => ({
    durationItems: [
      {
        key: '0:00',
        value: 0
      },
      {
        key: '0:30',
        value: .5,
      },
      {
        key: '1:00',
        value: 1,
      },
      {
        key: '1:30',
        value: 1.5,
      },
      {
        key: '2:00',
        value: 2,
      },
      {
        key: '2:30',
        value: 2.5,
      },
      {
        key: '3:00',
        value: 3,
      },
    ],
    dayOfTheWeekItems:[
      {
        key: 'Monday',
        value: 1
      },
      {
        key: 'Tuesday',
        value: 2
      },
      {
        key: 'Wednesday',
        value: 3
      },
      {
        key: 'Thursday',
        value: 4
      },
      {
        key: 'Friday',
        value: 5
      },
      {
        key: 'Saturday',
        value: 6
      },
      {
        key: 'Sunday',
        value: 0
      },
    ],
    eventTimeItems:[
      '00:00',
      '00:30',
      '01:00',
      '01:30',
      '02:00',
      '02:30',
      '03:00',
      '03:30',
      '04:00',
      '04:30',
      '05:00',
      '05:30',
      '06:00',
      '06:30',
      '07:00',
      '07:30',
      '08:00',
      '08:30',
      '09:00',
      '09:30',
      '10:00',
      '10:30',
      '11:00',
      '11:30',
      '12:00',
      '12:30',
      '13:00',
      '13:30',
      '14:00',
      '14:30',
      '15:00',
      '15:30',
      '16:00',
      '16:30',
      '17:00',
      '17:30',
      '18:00',
      '18:30',
      '19:00',
      '19:30',
      '20:00',
      '20:30',
      '21:00',
      '21:30',
      '22:00',
      '22:30',
      '23:00',
      '23:30',
      '24:00',
      '24:30',
    ],
    customGroup: {
      id: '',
      customUrl: '',
      generatedEditCode: '',
      customEvents: [
        {
          id: 0,
          event: "Loading",
          times: [],
          duration: {},
          daysOfTheWeek: []
        }
      ],
    },
    groupRef: null,
    formHasErrors: false,
    customUrlRules: [
      v => !!v || 'Custom URL is required',
      v => /^[a-zA-Z-]+$/.test(v) || 'Can only contain letters a \'-\'',
    ],
  }),

  methods: {
    deleteEvent(index){
      this.customGroup.customEvents.splice(index, 1)
    },
    addEvent(){
      let lastId = 1234560
      if(this.customGroup.customEvents.length !== 0){
        lastId = this.customGroup.customEvents[this.customGroup.customEvents.length-1].id;
      }
      lastId = lastId + 1
      let newEvent ={
        id: lastId,
        event: 'New Event',
        times: [],
        duration: 0,
        daysOfTheWeek: [],
      }

      this.customGroup.customEvents.push(newEvent)
    },
    async getGroup(){
      const customGroupCollectionRef = collection(db, "CustomGroups")
      const q = query(customGroupCollectionRef, where('generatedEditCode', '==', this.customGroup.generatedEditCode))

      const querySnap = await getDocs(q);
      querySnap.forEach((doc) => {
        this.customGroup = doc.data()
        this.customGroup.id = doc.id
      })
    },
    async updateGroup(){
      if(this.customGroup.customEvents.length > 5){
        alert('Cannot add more than five custom events. Please remove any extra events')
      }
      let _this = this
      this.customGroup.customEvents.forEach(function(event){
        if(event.event === ""){
          _this.formHasErrors = true
        }
        else if(event.times.length < 1){
          _this.formHasErrors = true
        }
        else if(event.daysOfTheWeek.length < 1){
          _this.formHasErrors = true
        }
        else{
          _this.formHasErrors = false
        }
      })
      if(!this.formHasErrors){
        const docRef = doc(db, 'CustomGroups', this.customGroup.id)
        await updateDoc(docRef, this.customGroup)
        await this.$router.push('/g/'+this.customGroup.customUrl)
      }

    },
    returnHome () {
      this.$router.push('/g/'+this.customGroup.customUrl)
    },
  },
  created(){
    let groupGuid = this.$route.params.groupGuid;
    this.customGroup.generatedEditCode = groupGuid;
    this.getGroup();
  }
}
</script>

<style scoped>

</style>