<template>
<!--  <div>-->
<!--&lt;!&ndash;    <h1>GroupUrl: {{ $route.params.groupUrl }}</h1>&ndash;&gt;-->
<!--&lt;!&ndash;    <HelloWorld />&ndash;&gt;-->
<!--  </div>-->
  <v-expansion-panel>
    <v-expansion-panel-header>
      <div class="boldHeader">
        {{$route.params.groupUrl}} Events
      </div>
      <p style="display: none">{{currentTime}}</p>
    </v-expansion-panel-header>
    <v-expansion-panel-content v-if="!$vuetify.breakpoint.xs">
      <v-row class="boldHeader">
        <v-col cols="4">Event</v-col>
        <v-col cols="4">Next Event Time</v-col>
        <v-col cols="4">Time Left</v-col>
      </v-row>
      <div v-for="event in customGroup.customEvents" :key="event.id">
        <v-row v-if="event.time === currentlyActive" style="color: green; font-weight: bold; align-items: center">
          <v-col cols="4"><div style="align-items: center" class="d-flex">{{ event.event }} <EventInfoDialog :event="event"/></div></v-col>
          <v-col cols="4">{{ event.time }}</v-col>
          <v-col cols="4">{{ event.timeLeft }}</v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="4" class="pr-0"><div style="align-items: center" class="d-flex">{{ event.event }} <EventInfoDialog :event="event"/></div></v-col>
          <v-col cols="4">{{ event.time }}</v-col>
          <v-col cols="4">{{ event.timeLeft }}</v-col>
        </v-row>
      </div>
    </v-expansion-panel-content>
    <v-expansion-panel-content v-else>
      <div style="border-bottom: solid; padding-top: 2em" v-for="event in customGroup.customEvents" :key="event.id">
        <div v-if="event.time === currentlyActive" style="color: green; font-weight: bold">
          <v-row class="left">
            <v-col>Event:</v-col>
            <v-col><div style="align-items: center" class="d-flex">{{ event.event }} <EventInfoDialog :event="event"/></div></v-col>
          </v-row>
          <v-row class="left">
            <v-col>Next Event Time:</v-col>
            <v-col>{{event.time}}</v-col>
          </v-row>
          <v-row class="left">
            <v-col>Time Left:</v-col>
            <v-col>{{event.timeLeft}}</v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row class="left">
            <v-col>Event:</v-col>
            <v-col><div style="align-items: center" class="d-flex">{{ event.event }} <EventInfoDialog :event="event"/></div></v-col>
          </v-row>
          <v-row class="left">
            <v-col>Next Event Time:</v-col>
            <v-col>{{event.time}}</v-col>
          </v-row>
          <v-row class="left">
            <v-col>Time Left:</v-col>
            <v-col>{{event.timeLeft}}</v-col>
          </v-row>
        </div>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>

import {getDocs, query, where, collection} from "firebase/firestore";
import db from "@/firebase";
import {DateTime} from "luxon";
import EventInfoDialog from "@/components/EventInfoDialog";

export default {
  name: "GroupView",
  components: {
    EventInfoDialog
  },
  props: [
      'serverConfig',
      'selectedServer',
  ],
  data: () => ({
    currentlyActive: "Currently Active",
    timerCount: 0,
    currentTime: "",
    customGroup: {
      customUrl: '',
      generatedEditCode: '',
      customEvents: [
        {
          id: 1000,
          event: '',
          times: [],
          time: '',
          timeLeft: '',
          duration: {},
          daysOfTheWeek: [],
        },
      ]
    },
  }),
  watch: {
    timerCount: {
      handler(value) {
        this.currentTime = `Current Server Time: ${this.convertToTime(new Date())}`

        let _this = this;


          this.customGroup.customEvents.forEach(function(event){
            // console.log('event')
            // console.log(event)
            let eventProperties = {
              eventDays: event.daysOfTheWeek,
              eventTimes: event.times,
              duration: event.duration,
              eventId: event.id,
              lookupNumber: 1
            }
            let nextEventDay = _this.getNextEventDayFromNow(eventProperties)
            event.time = nextEventDay.toLocaleString({
              weekday: 'long',
              hour: 'numeric',
              minute: 'numeric'
            })
            let date = DateTime.local().setZone(_this.getServerById(_this.selectedServer).timeZoneName)
            let diff = _this.timeDifferenceInSeconds(date, nextEventDay)
            event.timeLeft = _this.convertSecondsToTime(diff)
            if(diff < 0){
              let diff = _this.timeDifferenceInSeconds(date, nextEventDay.plus({hours: event.duration}))
              event.timeLeft = _this.convertSecondsToTime(diff)
              event.time = "Currently Active"
            }
          })
        // console.log(this.customGroup.customEvents[0].time)
        // console.log(this.customGroup.customEvents[0].timeLeft)

        if (value <= value) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    async getGroup(){
      const customGroupCollectionRef = collection(db, "CustomGroups")
      const q = query(customGroupCollectionRef, where('customUrl', '==', this.customGroup.customUrl))

      const querySnap = await getDocs(q);
      if(querySnap.docs.length < 1){
        await this.$router.push('/')
      }
      querySnap.forEach((doc) => {
        this.customGroup = doc.data()
        // console.log(doc.data())
        // console.log(this.customGroup.customEvents[0])
      })
    },
    getServerById(id){
      let server = this.serverConfig.serverLookup.servers.find(function(server) {
        if(server.id == id)
          return server;
      });
      return server;
    },
    convertToTime(date){
      date = new Date( date.getTime() - (date.getTimezoneOffset()));
      return date.toLocaleTimeString([],
          {timeZone:this.getServerById(this.selectedServer).timeZoneName})
    },
    getNextEventDayFromNow(event){
      let date = DateTime.local().setZone(this.getServerById(this.selectedServer).timeZoneName)
      let _this = this
      let breakout = false
      let nextServerTime = date

      for(let day of event.eventDays){
        day = day === 0 ? 7 : day
        for(let time of event.eventTimes){
          if(day >= date.weekday){
            let potentialNext = DateTime.fromISO(time, {zone:_this.getServerById(_this.selectedServer).timeZoneName}).plus({ days: day - date.weekday})
            if(potentialNext.plus({hours:event.duration}).diff(date) > 0){
              nextServerTime = potentialNext
              breakout = true
              break;
            }
          }
        }
        if (breakout){
          break;
        }
      }
      if(!breakout){
        for(let day of event.eventDays){
          day = day === 0 ? 7 : day
          for(let time of event.eventTimes){
            if(day + 7 >= date.weekday){
              let potentialNext = DateTime.fromISO(time, {zone:_this.getServerById(_this.selectedServer).timeZoneName}).plus({ days: day + 7 - date.weekday})
              if(potentialNext.plus({hours:event.duration}).diff(date) > 0){
                nextServerTime = potentialNext
                breakout = true
                break;
              }
            }
          }
          if (breakout){
            break;
          }
        }
      }
      return nextServerTime
    },
    timeDifferenceInSeconds(currentTime, eventTime) {
      let diff = currentTime.diff(eventTime)
      diff = eventTime.diff(currentTime)
      diff = Math.round((diff/1000));
      this.timeLeft = diff
      return diff
    },
    convertSecondsToTime(seconds){
      let hours = Math.floor(seconds / 3600)
      seconds %= 3600
      let minutes = Math.floor(seconds / 60)
      seconds = seconds % 60
      let timeString =
          `${hours.toString().padStart(2,'0')}:${minutes.toString().padStart(2,'0')}:${seconds.toString().padStart(2,'0')}`
      return timeString
    }
  },
  created(){
    let groupUrl = this.$route.params.groupUrl;
    this.customGroup.customUrl = groupUrl;
    this.getGroup()
  }
}
</script>

<style scoped>

</style>