<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
            fab
            x-small
            text
        >
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="primary">
          {{event.event}}
        </v-card-title>

        <v-card-text style="text-align: center" class="pt-12">
          <div style="text-align: left" class="pb-6" v-for="day in days" :key="day">
            {{day}}:
            <div style="text-align: center" v-for="time in event.times" :key="time">
              {{time}}
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "EventInfoDialog",
  props: ['event'],
  data () {
    return {
      dialog: false,
      days: [],
    }
  },
  created() {
    console.log(process.env.VUE_APP_TEST)
    this.event.daysOfTheWeek.forEach(day => {
      if(day === 1)
        this.days.push('Monday')
      if(day === 2)
        this.days.push('Tuesday')
      if(day === 3)
        this.days.push('Wednesday')
      if(day === 4)
        this.days.push('Thursday')
      if(day === 5)
        this.days.push('Friday')
      if(day === 6)
        this.days.push('Saturday')
      if(day === 0)
        this.days.push('Sunday')
    })
  }
}
</script>

<style scoped>

</style>