<template>
  <v-container>
    <v-btn icon @click="toggle_dark_mode">
      <v-icon>mdi-theme-light-dark</v-icon>
    </v-btn>
<!--    <v-btn @click="test">Test Button</v-btn>-->
    <v-row>
      <v-col cols="6">
        <v-select
            v-model="selectedServer"
            :items="serverConfig.serverLookup.servers"
            item-text="serverName"
            item-value="id"
            label="Server Name"
        ></v-select>
      </v-col>
      <v-col cols="6" style="align-self: center;">
        {{ currentTime }}
      </v-col>
    </v-row>

    <v-expansion-panels multiple>
      <GroupView v-if="$route.params.groupUrl"
          :selected-server="selectedServer"
          :server-config="serverConfig"/>
        <v-expansion-panel v-for="eventType in serverConfig.eventTypes" :key="eventType.name">
          <v-expansion-panel-header>
            <div class="boldHeader">
              {{eventType.name}}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content v-if="!$vuetify.breakpoint.xs">
            <v-row class="boldHeader">
              <v-col cols="4">Event</v-col>
              <v-col cols="4">Next Event Time</v-col>
              <v-col cols="4">Time Left</v-col>
            </v-row>
            <div v-for="event in eventType.events" :key="event.id">
              <v-row v-if="event.time === currentlyActive" style="color: green; font-weight: bold; align-items: center">
                <v-col cols="4"><div style="align-items: center" class="d-flex">{{ event.event }} <EventInfoDialog :event="event"/></div></v-col>
                <v-col cols="4">{{ event.time }}</v-col>
                <v-col cols="4">{{ event.timeLeft }}</v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="4"><div style="align-items: center" class="d-flex">{{ event.event }} <EventInfoDialog :event="event"/></div></v-col>
                <v-col cols="4">{{ event.time }}</v-col>
                <v-col cols="4">{{ event.timeLeft }}</v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
          <v-expansion-panel-content v-else>
            <div style="border-bottom: solid; padding-top: 2em" v-for="event in eventType.events" :key="event.id">
              <div v-if="event.time === currentlyActive" style="color: green; font-weight: bold">
                <v-row class="left">
                  <v-col>Event:</v-col>
                  <v-col><div style="align-items: center" class="d-flex">{{ event.event }} <EventInfoDialog :event="event"/></div></v-col>
                </v-row>
                <v-row class="left">
                  <v-col>Next Event Time:</v-col>
                  <v-col>{{event.time}}</v-col>
                </v-row>
                <v-row class="left">
                  <v-col>Time Left:</v-col>
                  <v-col>{{event.timeLeft}}</v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row class="left">
                  <v-col>Event:</v-col>
                  <v-col><div style="align-items: center" class="d-flex">{{ event.event }} <EventInfoDialog :event="event"/></div></v-col>
                </v-row>
                <v-row class="left">
                  <v-col>Next Event Time:</v-col>
                  <v-col>{{event.time}}</v-col>
                </v-row>
                <v-row class="left">
                  <v-col>Time Left:</v-col>
                  <v-col>{{event.timeLeft}}</v-col>
                </v-row>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    <div class="pt-8">Lead your own group/clan and want timers for your group? <v-btn text @click="$router.push('/creategroup')">Create Group</v-btn></div>
  </v-container>
</template>

<script>
import ServerConfig from '@/assets/ServerConfig.json';
import {DateTime} from 'luxon'
import GroupView from "@/components/GroupInformation/GroupView";
import EventInfoDialog from "@/components/EventInfoDialog";
// import ExpansionLookup from "@/components/ExpansionLookup";
// import GroupView from "@/components/GroupInformation/GroupView";
let serverConfigURL = `https://gist.githubusercontent.com/MRSessions/092c21f0538f3a0d767753c19dcc98d4/raw/DiabloImmortalEventTimes.json?_t=${new Date().getTime()}`

  export default {
    name: 'HelloWorld',
    components: {
      GroupView,
      EventInfoDialog
      // GroupView
      // ExpansionLookup
    },
    data: () => ({
      serverConfig: ServerConfig,
      servers: [],
      serverLookup: [],
      worldEventLookup: [],
      shadowEventLookup: [],
      timerCount: 0,
      currentTime: "",
      currentDateTime: "",
      timeLeft: "",
      currentlyActive: "Currently Active",
      nextEventTimes: [],
      selectedServer: ServerConfig.serverLookup.servers[0].id
    }),
    beforeCreate: function () {
      fetch(serverConfigURL)
          .then(r => r.json())
          .then(json => {
            this.serverConfig=json;
            this.selectedServer=this.serverConfig.serverLookup.servers[0].id
          });
    },
    mounted() {
      const theme = localStorage.getItem("dark_theme");
      if (theme) {
        if (theme === "true") {
          this.$vuetify.theme.dark = true;
        } else {
          this.$vuetify.theme.dark = false;
        }
      } else if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        this.$vuetify.theme.dark = true;
        localStorage.setItem(
            "dark_theme",
            this.$vuetify.theme.dark.toString()
        );
      }
    },
    watch: {
      timerCount: {
        handler(value) {
          this.currentTime = `Current Server Time: ${this.convertToTime(new Date())}`

          let _this = this;

          this.serverConfig.eventTypes.forEach(function(eventType){
            eventType.events.forEach(function(event){
              let eventProperties = {
                eventDays: event.daysOfTheWeek,
                eventTimes: event.times,
                duration: event.duration,
                eventId: event.id,
                lookupNumber: 1
              }
              let nextEventDay = _this.getNextEventDayFromNow(eventProperties)
              event.time = nextEventDay.toLocaleString({
                weekday: 'long',
                hour: 'numeric',
                minute: 'numeric'
              })
              let date = DateTime.local().setZone(_this.getServerById(_this.selectedServer).timeZoneName)
              let diff = _this.timeDifferenceInSeconds(date, nextEventDay)
              event.timeLeft = _this.convertSecondsToTime(diff)
              if(diff < 0){
                let diff = _this.timeDifferenceInSeconds(date, nextEventDay.plus({hours: event.duration}))
                event.timeLeft = _this.convertSecondsToTime(diff)
                event.time = "Currently Active"
              }
            })
          })

          if (value <= value) {
            setTimeout(() => {
              this.timerCount--;
            }, 1000);
          }
        },
        immediate: true // This ensures the watcher is triggered upon creation
      },
    },
    methods: {
      test(){
      },
      toggle_dark_mode: function() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
      },
      convertToTime(date){
        date = new Date( date.getTime() - (date.getTimezoneOffset()));
        return date.toLocaleTimeString([],
            {timeZone:this.getServerById(this.selectedServer).timeZoneName})
      },
      getServerById(id){
        let server = this.serverConfig.serverLookup.servers.find(function(server) {
          if(server.id == id)
            return server;
        });
        return server;
      },
      getNextEventDayFromNow(event){
        let date = DateTime.local().setZone(this.getServerById(this.selectedServer).timeZoneName)
        let _this = this
        let breakout = false
        let nextServerTime = date

        for(let day of event.eventDays){
          day = day === 0 ? 7 : day
          for(let time of event.eventTimes){
            if(day >= date.weekday){
              let potentialNext = DateTime.fromISO(time, {zone:_this.getServerById(_this.selectedServer).timeZoneName}).plus({ days: day - date.weekday})
              if(potentialNext.plus({hours:event.duration}).diff(date) > 0){
                nextServerTime = potentialNext
                breakout = true
                break;
              }
            }
          }
          if (breakout){
            break;
          }
        }
        if(!breakout){
          for(let day of event.eventDays){
            day = day === 0 ? 7 : day
            for(let time of event.eventTimes){
              if(day + 7 >= date.weekday){
                let potentialNext = DateTime.fromISO(time, {zone:_this.getServerById(_this.selectedServer).timeZoneName}).plus({ days: day + 7 - date.weekday})
                if(potentialNext.plus({hours:event.duration}).diff(date) > 0){
                  nextServerTime = potentialNext
                  breakout = true
                  break;
                }
              }
            }
            if (breakout){
              break;
            }
          }
        }
        return nextServerTime
      },
      timeDifferenceInSeconds(currentTime, eventTime) {
        let diff = currentTime.diff(eventTime)
        diff = eventTime.diff(currentTime)
        diff = Math.round((diff/1000));
        this.timeLeft = diff
        return diff
      },
      convertSecondsToTime(seconds){
        let hours = Math.floor(seconds / 3600)
        seconds %= 3600
        let minutes = Math.floor(seconds / 60)
        seconds = seconds % 60
        let timeString =
            `${hours.toString().padStart(2,'0')}:${minutes.toString().padStart(2,'0')}:${seconds.toString().padStart(2,'0')}`
        return timeString
      }
    }
  }
</script>

<style>
.boldHeader{
  font-weight: bold;
  text-align: center;
}
.left{
  text-align: left;
}
</style>
