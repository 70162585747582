import { render, staticRenderFns } from "./EventInfoDialog.vue?vue&type=template&id=89fd272e&scoped=true&"
import script from "./EventInfoDialog.vue?vue&type=script&lang=js&"
export * from "./EventInfoDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89fd272e",
  null
  
)

export default component.exports