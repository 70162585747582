<template>
  <div>
    <LoginForm v-if="login" />
    <SignupForm v-if="!login" />
    {{ formMessage }} <v-btn text @click="showLoginOrRegister(login)">{{ buttonText }}</v-btn>
  </div>
</template>

<script>
import LoginForm from "@/components/Authentication/LoginForm";
import SignupForm from "@/components/Authentication/SignupForm";

export default {
  name: "LoginRegister",
  components: {SignupForm, LoginForm},
  data: () => ({
    login: true,
    formMessage: 'Create account now?',
    buttonText: 'Register Now'
  }),
  methods:{
    showLoginOrRegister(login){
      this.login = !login
      if(this.login){
        this.buttonText = 'Register Now'
        this.formMessage = 'Create account now?'
      }else{
        this.buttonText = 'Login'
        this.formMessage = 'Already have an account?'
      }
    }
  }
}
</script>

<style scoped>

</style>