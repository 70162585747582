import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import LoginForm from "@/components/Authentication/LoginForm";
// import signupForm from "@/components/Authentication/SignupForm";
import LoginRegister from "@/views/LoginRegister";
import CreateGroup from "@/components/GroupInformation/GroupCreate";
// import GroupView from "@/components/GroupInformation/GroupView";
import GroupEdit from "@/components/GroupInformation/GroupEdit";
import HelloWorld from "@/components/HelloWorld";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/creategroup',
    name: 'creategroup',
    component: CreateGroup
  },
  {
    path: '/g/:groupUrl',
    name: "groupview",
    component: HelloWorld
  },
  // {
  //   path: '/g/:groupUrl',
  //   name: 'groupview',
  //   component: GroupView
  // },
  {
    path: '/groupedit/:groupGuid',
    name: 'groupedit',
    component: GroupEdit
  },
  {
    path: '/login-register',
    name: 'login-register',
    component: LoginRegister
  }
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: LoginForm
  // },
  // {
  //   path: '/signup',
  //   name: 'signup',
  //   component: signupForm
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
