<template>
  <v-row class="pt-16" justify="center">
    <v-col
        cols="12"
        sm="10"
        md="8"
        lg="6"
    >
      <v-card ref="form">
        <v-card-title style="justify-content: center">Create Group</v-card-title>
        <v-card-text class="px-8">
          <v-text-field
              ref="customUrl"
              v-model="formData.customUrl"
              :rules="customUrlRules"
              label="Custom URL (Ex. Clan Name)"
              required
              @keyup.space="insertDash"
          ></v-text-field>
          <v-text-field
              ref="generatedEditCode"
              v-model="formData.generatedEditCode"
              label="Generated Edit Code"
              hint="This is used when you need to edit your groups time information. Don't loose this!"
              required
              readonly
          ></v-text-field>
          <div>Please copy and do not loose this information!</div>
          <div>URL is how your members will view your added time information.</div>
          <div>Edit Code is how you can edit your group information.</div><br />
          <v-divider></v-divider>
          <div>
            <div>URL: https://ditimers.doomedgaming.com/g/{{ formData.customUrl }}</div>
            <div>Edit: https://ditimers.doomedgaming.com/groupedit/{{ formData.generatedEditCode}}</div>
          </div>
        </v-card-text>
        <v-divider class="mt-12"></v-divider>
        <v-card-actions class="px-8">
          <v-btn text @click="returnHome()">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-slide-x-reverse-transition>
            <v-tooltip
                v-if="formHasErrors"
                left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    class="my-0"
                    v-bind="attrs"
                    @click="resetForm"
                    v-on="on"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh form</span>
            </v-tooltip>
          </v-slide-x-reverse-transition>
          <v-btn
              color="primary"
              text
              @click="submit"
          >
            Create Group
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { uuid } from 'vue-uuid';
import db from '@/firebase'
import {addDoc, collection, getDocs, query, where} from 'firebase/firestore'

export default {
  name: "CreateGroup",

  data: () => ({
    formData: {
      customUrl: '',
      generatedEditCode: uuid.v4(),
      customEvents: []
    },
    errorMessages: '',
    formHasErrors: false,
    customUrlRules: [
      v => !!v || 'Custom URL is required',
      v => /^[a-zA-Z-]+$/.test(v) || 'Can only contain letters a \'-\'',
    ],
    // show1: false,
    // password: '',
    // rules: {
    //   required: value => !!value || 'Required.',
    //   min: v => v.length >= 6 || 'Min 6 characters',
    //   emailMatch: () => (`The email and password you entered don't match`),
    // },

  }),

  computed: {
    form () {
      return {
        customUrl: this.formData.customUrl,
        generatedEditCode: this.formData.generatedEditCode
      }
    },
  },

  watch: {
    name () {
      this.errorMessages = ''
    },
  },

  methods: {
    insertDash(){
      this.formData.customUrl = this.formData.customUrl.replace(' ', '-')
    },
    resetForm () {
      this.errorMessages = []
      this.formHasErrors = false

      Object.keys(this.form).forEach(f => {
        this.$refs[f].reset()
      })

      this.formData.generatedEditCode = uuid.v4()
    },
    submit () {
      this.formHasErrors = false

      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) this.formHasErrors = true

        this.$refs[f].validate(true)
      })
      if(!this.formHasErrors){
        this.saveToDb()
      }
    },
    async saveToDb(){
      const customGroupCollectionRef = collection(db, "CustomGroups")
      //TODO: Validate then save to Firebase

      if(!await this.nameTaken()){
        // const document = await addDoc(customGroupCollectionRef, this.formData)
        await addDoc(customGroupCollectionRef, this.formData)

        // console.log(document)
        await this.$router.push('/groupedit/' + this.formData.generatedEditCode)
      }
      else{
        alert('That name is already taken, please choose another')
      }

    },
    async nameTaken(){
      const customGroupCollectionRef = collection(db, "CustomGroups")
      const q = query(customGroupCollectionRef, where('customUrl', '==', this.formData.customUrl))

      const querySnap = await getDocs(q);
      console.log(querySnap.docs)
      if(querySnap.docs.length >= 1){
        return true
      }
      else{
        return false
      }
    },
    returnHome () {
      this.$router.push('/')
    },
  },
}
</script>

<style scoped>

</style>